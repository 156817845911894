import $api from '../http';

export default class StockService {
  static async getOrders() {
    return $api.get(`/stock/orders`);
  }

  static async findItem(code) {
    return $api.get(`/stock?i=${code}`);
  }
  static async findOrder(code) {
    return $api.get(`/stock?o=${code}`);
  }

  static async manageItem(data) {
    return $api.post('/stock', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  static async repack(data) {
    return $api.post('/stock/repack', data);
  }

  static async send(data) {
    return $api.post('/stock/send', data);
  }

  static async accept(data) {
    return $api.post('/stock/accept', data);
  }

  static async issue(data, find = '') {
    return $api.post(`/stock/giveout/${find}`, data);
  }

  static async getStockImages(id) {
    return $api.get(`/orders/stock/images/${id}`);
  }
}
