import { useEffect, useState } from 'react';

const useValidation = (value, validations) => {
  const [isEmpty, setEmpty] = useState(true);
  const [minLengthError, setMinLengthError] = useState(false);
  const [isCompare, setCompare] = useState(false);
  const [isEmail, setEmail] = useState(false);
  const [lenError, setLen] = useState(false);

  const [errors, setMessage] = useState([]);
  const [err, setErr] = useState(true);

  useEffect(() => {
    for (let validation in validations) {
      switch (validation) {
        case 'length':
          if (value.length == validations[validation]) {
            if (lenError) {
              setLen(false);
              setMessage(errors.filter((el) => el.id !== validation));
            }
          } else {
            if (!lenError) {
              setLen(true);
              setMessage([
                ...errors,
                {
                  id: validation,
                  message: `Incorrect length`,
                },
              ]);
            }
          }
          break;

        case 'minLength':
          if (value.length < validations[validation]) {
            if (!minLengthError) {
              setMinLengthError(true);
              setMessage([
                ...errors,
                {
                  id: validation,
                  message: `Minimal lenght ${validations[validation]} symbols`,
                },
              ]);
            }
          } else {
            if (minLengthError) {
              setMinLengthError(false);
              setMessage(errors.filter((el) => el.id !== validation));
            }
          }
          break;

        case 'isEmpty':
          if (value) {
            if (isEmpty) {
              setEmpty(false);
              setMessage(errors.filter((el) => el.id !== validation));
            }
          } else {
            if (!isEmpty) {
              setEmpty(true);
              setMessage([
                ...errors,
                {
                  id: validation,
                  message: 'A field cannot be empty',
                },
              ]);
            }
          }
          break;

        case 'compare':
          if (value != validations[validation]) {
            if (isCompare) {
              setCompare(false);
              setMessage([
                ...errors,
                { id: validation, message: 'Passwords do not match' },
              ]);
            }
          } else {
            if (!isCompare) {
              setCompare(true);
              setMessage(errors.filter((el) => el.id !== validation));
            }
          }
          break;

        case 'email':
          if (
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
              value
            )
          ) {
            if (!isEmail) {
              setEmail(true);
              setMessage(errors.filter((el) => el.id !== validation));
            }
          } else {
            if (isEmail) {
              setEmail(false);
              setMessage([
                ...errors,
                { id: validation, message: 'This is not email' },
              ]);
            }
          }
          break;
      }
    }
  }, [value]);

  useEffect(() => {
    if (!isEmpty) setErr(!!errors.length);
    else setErr(true);
  }, [errors]);

  return {
    err,
    errors,
  };
};

export default useValidation;
