import React, { useEffect } from 'react';
import {
  Button,
  Spinner,
  Center,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Img,
  Box,
} from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import { useStore } from '../hooks/useStore';

const StockImages = ({ isOpen, onClose, orderItem }) => {
  const { store } = useStore();

  useEffect(() => {
    if (isOpen) store.getStockImages(orderItem);
    return () => {
      store.setStockImages();
    };
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Фото со склада</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {store.stockImages.length ? (
            store.stockImages.map((img) => <Img src={img.link} key={img.id} />)
          ) : (
            <Center flex="1">
              <Spinner thickness="4px" size="xl" color="green" />
            </Center>
          )}
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onClose}>
            OK
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default observer(StockImages);
