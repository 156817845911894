import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Image,
} from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import MessageBox from '../components/MessageBox';
import { useStore } from '../hooks/useStore';

function PickupPage() {
  const { store } = useStore();
  const [code, setCode] = useState('');
  const [weight, setWeight] = useState('');

  useEffect(() => {
    return () => {
      store.setFIndOrder();
    };
  }, []);

  return (
    <Flex direction="column" mt="20px" align="center">
      <Box w="90%" maxW="500px" m="auto">
        <Box bg="white" borderRadius="md" p="30px" boxShadow="lg">
          {store.order ? (
            <>
              <Flex mb="20px" gap="20px">
                <Box>
                  <Box fontWeight="bold" color="orange">
                    {store.order.code}
                  </Box>
                  <Box>
                    Send date -{' '}
                    {new Date(store.order.orderDate).toLocaleDateString()}
                  </Box>
                </Box>
                <Box>
                  Expected weight
                  <Box>{store.order.sendWeight} kg</Box>
                </Box>
              </Flex>
              <FormControl mb="20px">
                <FormLabel>Weight</FormLabel>
                <Flex gap="20px">
                  <Input
                    value={weight}
                    onChange={(e) => setWeight(e.target.value)}
                  />
                </Flex>
              </FormControl>
              <Center mt="20px">
                <Button
                  disabled={!weight}
                  colorScheme="green"
                  onClick={() => store.acceptOrder(code, weight)}
                  isLoading={store.loading}
                >
                  Confirm
                </Button>
              </Center>
            </>
          ) : (
            <FormControl>
              <FormLabel>Shipped order</FormLabel>
              <Flex gap="20px">
                <Input
                  value={code}
                  placeholder="Order code"
                  onChange={(e) => setCode(e.target.value)}
                />
                <Button
                  colorScheme="green"
                  onClick={() => store.findOrder(code)}
                  isLoading={store.loading}
                >
                  Find
                </Button>
              </Flex>
            </FormControl>
          )}
        </Box>
      </Box>
      <MessageBox
        store={store}
        callback={() => {
          store.setFIndOrder();
          setWeight('');
          setCode('');
        }}
      />
    </Flex>
  );
}

export default observer(PickupPage);
