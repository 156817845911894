import { extendTheme } from '@chakra-ui/react';
import { defineStyleConfig } from '@chakra-ui/react';

const Button = defineStyleConfig({
  variants: {
    ghost: {
      _hover: {
        bg: 'white',
      },
    },
  },
});

export const main = extendTheme({
  colors: {
    brand: {
      100: '#f7fafc',
      // ...
      900: '#1a202c',
    },
  },
  components: {
    Button,
  },
});
