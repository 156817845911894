import React, { useEffect, useState } from 'react';
import {
  Box,
  IconButton,
  Button,
  Flex,
  Image,
  Center,
  filter,
} from '@chakra-ui/react';
import FileUpload from './FileUpload';
import { AddIcon } from '@chakra-ui/icons';
import { observer } from 'mobx-react-lite';
import AddImagesCard from './AddImagesCard';

const ImgForm = ({ store }) => {
  const fileHendler = (e) => {
    store.setImages([...e.target.files]);
  };

  const removeFileHandler = (key) => {
    store.removeImage(key);
  };

  return (
    <Flex
      gap="20px"
      alignItems="center"
      mt="20px"
      bg="gray.100"
      p="20px"
      border="1px"
      borderRadius="md"
      borderColor="gray.300"
    >
      <Box flex="1">
        {store.preview.length ? (
          <Flex gap="10px">
            {store.preview.map((img, i) => (
              <AddImagesCard
                key={img.id}
                img={img}
                handler={removeFileHandler}
              />
            ))}
            <FileUpload accept={'image/*'} multiple hendler={fileHendler}>
              <IconButton
                w="80px"
                h="80px"
                variant="outline"
                aria-label="Add images"
                icon={<AddIcon />}
              />
            </FileUpload>
          </Flex>
        ) : (
          <>
            <Center>Upload product images</Center>
            <Center mt="10px">
              <FileUpload accept={'image/*'} multiple hendler={fileHendler}>
                <IconButton
                  variant="outline"
                  aria-label="Add images"
                  icon={<AddIcon />}
                />
              </FileUpload>
            </Center>
          </>
        )}
      </Box>

      <Box></Box>
    </Flex>
  );
};

export default observer(ImgForm);
