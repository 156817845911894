import useValidation from './useValidation';

const { useState } = require('react');

const useInput = (initialValue, validations) => {
  const [value, setValue] = useState(initialValue);
  const [isDirty, setIsDirty] = useState(false);
  const valid = useValidation(value, validations);

  const onChange = (e) => {
    setValue(e.target.value);
  };

  const onBlur = (e) => {
    setIsDirty(true);
  };

  return { props: { value, onChange, onBlur }, isDirty, ...valid };
};

export default useInput;
