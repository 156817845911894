import React, { useContext, useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightAddon,
} from '@chakra-ui/react';
import { Link as rLink } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStore } from '../hooks/useStore';

const ConfirmForm = ({ opened, close, orderId, loading }) => {
  const { store } = useStore();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [weight, setWeight] = useState('');
  const [price, setPrice] = useState('');
  const [cargoCode, setCargoCode] = useState('');

  return (
    <>
      <Modal
        isOpen={opened}
        onClose={() => {
          onClose();
          close(false);
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Repack data</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl mb="20px">
              <FormLabel>Package weight</FormLabel>
              <InputGroup>
                <Input
                  value={weight}
                  onChange={(e) => setWeight(e.target.value)}
                />
                <InputRightAddon children="kg" />
              </InputGroup>
            </FormControl>
            <FormControl mb="20px">
              <FormLabel>Shipping amount</FormLabel>
              <InputGroup>
                <Input
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                />
                <InputRightAddon children="$" />
              </InputGroup>
            </FormControl>
            <FormControl>
              <FormLabel>CARGO track number</FormLabel>
              <InputGroup>
                <Input
                  value={cargoCode}
                  onChange={(e) => setCargoCode(e.target.value)}
                />
              </InputGroup>
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              isLoading={loading}
              onClick={() => {
                store.repackOrder(orderId, {
                  weight,
                  price,
                  cargoCode,
                });
                close(false);
              }}
            >
              Confirm
            </Button>
            <Button
              variant="ghost"
              onClick={() => {
                onClose();
                close(false);
              }}
            >
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default observer(ConfirmForm);
