import { InputGroup, Box, Center } from '@chakra-ui/react';
import React, { useRef } from 'react';

const FileUpload = (props) => {
  const { accept, multiple, children, hendler, ...other } = props;
  const inputRef = useRef(null);

  const handleClick = () => inputRef.current?.click();

  return (
    <Box onClick={handleClick} {...other} as="span">
      <input
        type={'file'}
        multiple={multiple || false}
        hidden
        accept={accept}
        onChange={(e) => hendler(e)}
        ref={(e) => {
          inputRef.current = e;
        }}
      />
      <>
        <Center>{children}</Center>
      </>
    </Box>
  );
};

export default FileUpload;
