import {
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Image,
} from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import { useStore } from '../hooks/useStore';
import { observer } from 'mobx-react-lite';
import MessageBox from '../components/MessageBox';
import FindItem from '../components/FindItem';
import LinkItem from '../components/LinkItem';
import ImgForm from '../components/ImgForm';

const StockMainPage = () => {
  const { store } = useStore();
  const [code, setCode] = useState('');

  useEffect(() => {
    return () => {
      store.setItem();
      store.setLinkItem();
    };
  }, []);

  return (
    <Flex direction="column" mt="20px" align="center">
      <Box w="90%" maxW="500px" m="auto">
        <Box bg="white" borderRadius="md" p="30px" boxShadow="lg">
          {store.item || store.linkItem ? (
            <>
              {store.item && <FindItem />}
              {store.linkItem && <LinkItem />}
              <FormControl mb="20px">
                <FormLabel>Count</FormLabel>
                <Flex gap="20px">
                  <Input
                    value={store.sendCount}
                    onChange={(e) => store.setCount(e.target.value)}
                  />
                </Flex>
              </FormControl>
              <ImgForm store={store} />
              <Center mt="20px">
                <Button
                  colorScheme="green"
                  onClick={() => store.sendConfirm()}
                  isLoading={store.loading}
                >
                  Confirm
                </Button>
              </Center>
            </>
          ) : (
            <FormControl>
              <FormLabel>Find a delivered item</FormLabel>
              <Flex gap="20px">
                <Input
                  value={code}
                  placeholder="Item Code"
                  onChange={(e) => setCode(e.target.value)}
                />
                <Button
                  colorScheme="green"
                  onClick={() => {
                    store.findItem(code);
                    setCode('');
                  }}
                  isLoading={store.loading}
                >
                  Find
                </Button>
              </Flex>
            </FormControl>
          )}
        </Box>
      </Box>
      <MessageBox store={store} />
    </Flex>
  );
};

export default observer(StockMainPage);
