import { observer } from 'mobx-react-lite';
import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Flex,
  Center,
  Heading,
  Divider,
} from '@chakra-ui/react';
import { useStore } from '../hooks/useStore';
import OrderCard from '../components/OrderCard';
import OrderCardBody from '../components/OrderCardBody';

function GiveoutPage() {
  const { store } = useStore();
  const [code, setCode] = useState('');

  useEffect(() => {
    return () => {
      store.setFIndOrder();
    };
  }, []);
  return (
    <>
      <Flex direction="column" mt="20px" align="center">
        <Box w="90%" maxW="500px" m="auto">
          <Box bg="white" borderRadius="md" p="30px" boxShadow="lg">
            <FormControl>
              <FormLabel>Give out order</FormLabel>
              <Flex gap="20px">
                <Input
                  value={code}
                  placeholder="Give out code"
                  onChange={(e) => setCode(e.target.value)}
                />

                <Button
                  colorScheme="green"
                  onClick={() => store.issOrder(code, 'find')}
                  isLoading={store.loading}
                >
                  Find
                </Button>
              </Flex>
            </FormControl>
          </Box>
        </Box>
      </Flex>
      {store.order && (
        <Box
          mt="40px"
          mx="auto"
          w="90%"
          maxW="960px"
          bg="white"
          borderRadius="md"
          p="30px"
          boxShadow="lg"
        >
          <Flex gap="20px">
            <Heading color="orange" size="md" mb="10px">
              {store.order.code}
            </Heading>
            <Box>
              {store.order.status === 'issued' && (
                <Box bg="green.500" color="white" fontWeight="strong">
                  {store.order.status}
                </Box>
              )}
            </Box>
          </Flex>
          <Divider mb="30px" />
          <OrderCardBody order={store.order} />
          {store.order.status !== 'issued' && (
            <Center>
              <Button
                onClick={() => {
                  store.issOrder(code);
                  setCode('');
                  setTimeout(() => store.setFIndOrder(), 1000);
                }}
                isLoading={store.loading}
                colorScheme="green"
              >
                Issue
              </Button>
            </Center>
          )}
        </Box>
      )}
    </>
  );
}

export default observer(GiveoutPage);
