import {
  Box,
  Button,
  Center,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  PinInput,
  PinInputField,
  Spinner,
  useDisclosure,
} from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import React from 'react';

const SmsForm = ({ registerStore }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Modal isOpen={registerStore.isEnterCode} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textAlign="center">Enter SMS code</ModalHeader>
        <ModalBody>
          <Center>
            {registerStore.loading ? (
              <Spinner />
            ) : (
              <>
                <HStack>
                  <PinInput
                    onComplete={(value) => registerStore.checkPhone(value)}
                    isInvalid={!!registerStore.message}
                  >
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                  </PinInput>
                </HStack>
              </>
            )}
          </Center>
          {!!registerStore.message && (
            <Box mt="20px" w="full" textAlign="center" color="red">
              {registerStore.message}
            </Box>
          )}
          <Center mt="20px">
            <Button
              variant="ghost"
              size="xs"
              onClick={() => registerStore.setEnterCode(false)}
            >
              Return
            </Button>
          </Center>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default observer(SmsForm);
