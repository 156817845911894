import React from 'react';
import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Image,
  Spacer,
} from '@chakra-ui/react';
import { Link as rLink } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import UserMenu from './UserMenu';
import { useStore } from '../hooks/useStore';
import { useNotifications } from '../hooks/useNotifications';

const Header = () => {
  const { store } = useStore();

  useNotifications(store.userData?.id);

  return (
    <Box
      w="full"
      pb="5px"
      boxShadow="lg"
      bgGradient="radial-gradient(100% 11729.68% at 19.9% 0%, #007A4C 0%, #9DC41A 28.65%, #FCC330 74.72%, #C81742 100%)"
      borderBottomRadius="md"
    >
      <Box h="5px"></Box>
      <Box bg="white" pos="absolute" h="62px" w="full" zIndex="0"></Box>
      <Flex
        px="20px"
        maxW="1100px"
        m="auto"
        mb="5px"
        alignItems="end"
        zIndex="10"
        pos="relative"
        justify="center"
      >
        <Flex
          alignItems="end"
          py="10px"
          px="28px"
          bg="white"
          borderBottomRadius="md"
        >
          <Heading color="gray.400" size="lg" fontWeight="normal">
            Stock
          </Heading>
          <Box as={rLink} to="/">
            <Image h="70px" src="minLogo.svg" />
          </Box>
        </Flex>

        {store.isAuth && (
          <>
            <Spacer />
            <HStack mb="10px">
              {['stockTR', 'stock'].includes(store.userData?.role) && (
                <Button as={rLink} to="/takeitem" variant="ghost">
                  Take item
                </Button>
              )}
              {['stockRU', 'stock'].includes(store.userData?.role) && (
                <>
                  <Button as={rLink} to="/pickup" variant="ghost">
                    Pick up order
                  </Button>
                  <Button as={rLink} to="/giveout" variant="ghost">
                    Give out order
                  </Button>
                </>
              )}
              <Button as={rLink} to="/orders" variant="ghost">
                Orders
              </Button>
              <UserMenu />
            </HStack>
          </>
        )}
      </Flex>
    </Box>
  );
};

export default observer(Header);
