import { createContext, useContext } from 'react';

export const StoreContext = createContext(null);

export const useStore = () => {
  const context = useContext(StoreContext);
  if (context === null) {
    throw new Error(
      'You have forgotten to wrap your root component with RootStoreProvider'
    );
  }
  return context;
};
