import { makeAutoObservable } from 'mobx';
import AuthService from '../services/AuthService';
import UserService from '../services/UserService';
import StockService from '../services/StockService';

export default class Store {
  isAuth = false;
  linkItem = null;
  item = null;
  images = [];
  preview = [];
  loading = false;
  error = null;
  userData = null;
  message = null;
  messages = [];
  sendCount = '';
  orders = [];
  order = null;
  stockImages = [];

  constructor() {
    makeAutoObservable(this);
  }

  setAuth(bool) {
    this.isAuth = bool;
  }

  setUserData(user) {
    this.userData = user;
  }

  setLoading(bool) {
    this.loading = bool;
  }

  setError(err) {
    this.error = err;
  }

  setMessage(msg) {
    this.message = msg;
  }

  setOrders(orders) {
    this.orders = orders;
  }

  setFIndOrder(order = null) {
    this.order = order;
  }

  setOrder(order) {
    const oidx = this.orders.findIndex((ord) => ord.id === order.id);
    // const idx = this.orders[oidx].items.findIndex((item) => item.id === itemId);
    this.orders[oidx] = order;
  }

  setItem(itemData = null) {
    this.item = itemData;
  }
  setLinkItem(itemData = null) {
    this.linkItem = itemData;
  }

  setCount(count) {
    this.sendCount = count;
  }

  setNewImages(imgArr) {
    this.images.push(...imgArr);
  }
  removeImage(imgId) {
    if (this.images.length) {
      this.images = this.images.filter((img) => img.id !== imgId);
    }
  }
  setImages(images) {
    const files = this.images.map((f) => f.name);
    let compare = false;
    images.forEach((img) => {
      if (files.includes(img.name)) {
        compare = true;
        return;
      }
      this.images.push(img);
    });
    if (!compare) this.setPreview(images);
  }

  setStockImages(images = []) {
    this.stockImages = images;
  }

  clearImages() {
    this.images = [];
    this.preview = [];
  }

  setPreview(images) {
    images.forEach((img) => {
      const image = URL.createObjectURL(img);
      this.preview.push({ id: img.size + img.lastModified, src: image });
    });
  }

  async login(login, password) {
    this.setLoading(true);
    try {
      const { data } = await AuthService.login(login, password);
      localStorage.setItem('sttk', data.accessToken);
      this.checkAuth();
    } catch (e) {
      this.setError(e);
    } finally {
      this.setLoading(false);
    }
  }

  async logout() {
    this.setLoading(true);
    try {
      await AuthService.logout();
      localStorage.removeItem('sttk');
      this.setAuth(false);
      this.setUserData(null);
    } catch (e) {
      this.setError(e);
    } finally {
      this.setLoading(false);
    }
  }

  async checkAuth() {
    this.setLoading(true);
    try {
      const { data } = await UserService.getCurrent();
      const { vendor, ...user } = data;
      this.setUserData(user);
      this.setAuth(true);
    } catch (e) {
      this.setError(e);
    } finally {
      this.setLoading(false);
    }
  }

  async updateUser(userData) {
    this.setLoading(true);
    try {
      const { data } = await UserService.update(userData);
      this.setUserData(data.data);
    } catch (e) {
      this.setError(e);
    } finally {
      this.setLoading(false);
    }
  }

  async resendEmailLink(userData) {
    this.setLoading(true);
    try {
    } catch (e) {
      this.setError(e);
    } finally {
      this.setLoading(false);
    }
  }

  async findItem(code) {
    if (!code) return;
    this.setLoading(true);
    try {
      const { data } = await StockService.findItem(code);
      const { items, linkItems } = data;
      if (items && items?.length) {
        this.setItem(items[0]);
      }
      if (linkItems && linkItems?.length) {
        this.setLinkItem(linkItems[0]);
      }
    } catch (e) {
      this.setError(e);
    } finally {
      this.setLoading(false);
    }
  }
  async findOrder(code) {
    if (!code) return;
    this.setLoading(true);
    try {
      const { data } = await StockService.findOrder(code);
      this.setFIndOrder(data);
    } catch (e) {
      this.setError(e);
    } finally {
      this.setLoading(false);
    }
  }

  async sendConfirm() {
    this.setLoading(true);
    try {
      if (
        (this.linkItem || (this.item && this.item.code)) &&
        this.images.length &&
        this.sendCount
      ) {
        const sendData = new FormData();
        if (this.linkItem) {
          sendData.append('itemCode', this.linkItem.code);
        } else {
          sendData.append('itemCode', this.item.code);
        }
        sendData.append('itemCount', this.sendCount);
        this.images.map((img) => sendData.append('img', img));

        await StockService.manageItem(sendData);
        this.setMessage('Item confirmed');
        this.setItem(null);
        this.setLinkItem(null);
        this.setCount('');
        this.clearImages();
      } else {
        this.setMessage('Incorrect data');
        console.log('Incorrect data');
      }
    } catch (e) {
      this.setError(e);
    } finally {
      this.setLoading(false);
    }
  }

  async repackOrder(id, repackData) {
    this.setLoading(true);
    try {
      const { data } = await StockService.repack({ id, ...repackData });
      this.setOrder(data);
    } catch (e) {
      this.setError(e);
    } finally {
      this.setLoading(false);
    }
  }

  async sendOrder(id) {
    this.setLoading(true);
    try {
      const { data } = await StockService.send({ id });
      this.setOrder(data);
    } catch (e) {
      this.setError(e);
    } finally {
      this.setLoading(false);
    }
  }

  async getOrders() {
    this.setLoading(true);
    try {
      const { data } = await StockService.getOrders();
      this.setOrders(data);
    } catch (e) {
      this.setError(e);
    } finally {
      this.setLoading(false);
    }
  }
  async acceptOrder(code, weight) {
    this.setLoading(true);
    try {
      const { data } = await StockService.accept({ code, weight });
      this.setOrder(data);
      this.setMessage('Order accepted');
    } catch (e) {
      this.setError(e);
    } finally {
      this.setLoading(false);
    }
  }
  async issOrder(code, find = '') {
    this.setLoading(true);
    try {
      const { data } = await StockService.issue({ code }, find);
      this.setFIndOrder(data);
    } catch (e) {
      this.setError(e);
    } finally {
      this.setLoading(false);
    }
  }

  async getStockImages(id) {
    try {
      const { data } = await StockService.getStockImages(id);
      this.setStockImages(data);
    } catch (e) {
      this.setError(e);
    }
  }
}
