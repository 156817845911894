import React from 'react';
import {
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Image,
  Link,
  HStack,
  Text,
} from '@chakra-ui/react';
import { LinkIcon } from '@chakra-ui/icons';
import { useStore } from '../hooks/useStore';

export default function FindItem() {
  const { store } = useStore();

  return (
    <>
      <Flex mb="20px">
        <Box fontWeight="bold" color="orange">
          {store.linkItem.code}
        </Box>
        <Flex gap="20px">
          <Box>
            {!!store.linkItem.img && (
              <Image
                objectFit="cover"
                boxSize="50px"
                src={store.linkItem.img}
              />
            )}
          </Box>
          <Box>
            <Link href={store.linkItem.link} target="_blank">
              <HStack>
                <LinkIcon />
                <Text fontSize="xs" color="blue" noOfLines={1} w="250px">
                  {store.linkItem.link}
                </Text>
              </HStack>
            </Link>
            <Flex gap="20px">
              <Box>Ожидаемое колличество</Box>
              <Text fontWeight="bold">{store.linkItem.count}</Text>
            </Flex>
          </Box>
        </Flex>
      </Flex>
    </>
  );
}
