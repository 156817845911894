import React from 'react';
import { Box, Flex, Image } from '@chakra-ui/react';
import { useStore } from '../hooks/useStore';

export default function FindItem() {
  const { store } = useStore();

  return (
    <>
      <Flex mb="20px">
        <Box fontWeight="bold" color="orange">
          {store.item.code}
        </Box>
        <Flex gap="20px">
          <Box>
            {!!store.item.images.length && (
              <Image
                objectFit="cover"
                boxSize="50px"
                src={store.item.images[0].link}
              />
            )}
          </Box>
          <Box>
            <Box>{store.item.name}</Box>
            <Box fontSize="12px">
              Упаковка: {store.item.pkg_x} x {store.item.pkg_y} x{' '}
              {store.item.pkg_z} Вес: {store.item.pkg_w}
            </Box>
          </Box>
          <Box>
            <Box>Ожидаемое колличество</Box>
            <Box>{store.item.orderCount}</Box>
          </Box>
        </Flex>
      </Flex>
    </>
  );
}
