import { useEffect } from 'react';
import { useStore } from './useStore';

export const useNotifications = (id) => {
  const { store } = useStore();

  const subscribe = async (id) => {
    const eventSource = new EventSource(
      `http://localhost:5000/api/notifications/${id}`
    );
    eventSource.onmessage = function (event) {
      const { type } = JSON.parse(event.data);

      switch (type) {
        case 'newOrder':
          store.getOrders();
          break;

        case 'newVendor':
          break;

        case 'message':
          break;
      }
    };
  };

  useEffect(() => {
    if (id) subscribe(id);
  }, []);
};
