import {
  Avatar,
  Box,
  Text,
  Flex,
  FormControl,
  Input,
  FormLabel,
  Button,
  Center,
  Badge,
  Tag,
} from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import React, { useState, useEffect, useContext } from 'react';
import UserService from '../services/UserService';
import FileUpload from './FileUpload';
import { useStore } from '../hooks/useStore';

const colors = {
  active: 'green',
  blocked: 'red',
};

const UserSetup = () => {
  const { store } = useStore();

  const [user, setUser] = useState({
    avatar: store.userData.avatar,
    email: store.userData.email,
    login: store.userData.login || '',
    name: store.userData.name || '',
    phone: store.userData.phone,
    second_name: store.userData.second_name || '',
  });

  const [check, setCheck] = useState({ email: false, login: false });

  const [avatar, setAvatar] = useState(null);

  const fileHendler = (e) => {
    const img = e.target.files[0];
    setUser({ ...user, avatar: img });
    const image = URL.createObjectURL(img);
    setAvatar(image);
  };

  const checkField = async (e) => {
    const { data } = await UserService.checkField({
      [e.target.name]: e.target.value,
    });
    setCheck({ ...check, [e.target.name]: data.find });
  };

  const sendHandler = async () => {
    store.updateUser(user);
  };

  return (
    <Flex gap="20px">
      <Box bg="white" borderRadius="md" p="20px" align="center">
        <Avatar size="lg" src={avatar || user.avatar} mb="20px" />
        <Box>
          <Text as="b">{user.login || user.email}</Text>
        </Box>
        <Box mt="30px">
          Status:{' '}
          <Tag borderRadius="full" colorScheme={colors[store.userData.status]}>
            {store.userData.status}
          </Tag>
        </Box>
      </Box>
      <Flex bg="white" borderRadius="md" p="40px" flex="1">
        <Box>
          <Avatar boxSize="200px" src={avatar || user.avatar} mb="30px" />
          <FileUpload accept={'image/*'} hendler={fileHendler}>
            <Button>Upload image</Button>
          </FileUpload>
        </Box>
        <Box ml="40px" flex="1">
          <FormControl mb="20px">
            <FormLabel>Login</FormLabel>
            <Input
              type="text"
              name="login"
              bg={check.login ? 'red.100' : ''}
              errorBorderColor="red.300"
              value={user.login}
              onChange={(e) => {
                checkField(e);
                setUser({ ...user, [e.target.name]: e.target.value });
              }}
              isDisabled={store.loading}
            />
          </FormControl>
          <FormControl mb="20px">
            <FormLabel>Name</FormLabel>
            <Input
              type="text"
              name="name"
              value={user.name}
              onChange={(e) => {
                setUser({ ...user, [e.target.name]: e.target.value });
              }}
              isDisabled={store.loading}
            />
          </FormControl>
          <FormControl mb="20px">
            <FormLabel>Second name</FormLabel>
            <Input
              type="text"
              name="second_name"
              value={user.second_name}
              onChange={(e) =>
                setUser({ ...user, [e.target.name]: e.target.value })
              }
              isDisabled={store.loading}
            />
          </FormControl>
          <FormControl mb="20px">
            <FormLabel>
              E-mail{' '}
              {store.userData.emailConfirm || (
                <Badge colorScheme="red">Not confirmed</Badge>
              )}
            </FormLabel>
            <Input
              type="text"
              name="email"
              bg={check.email ? 'red.100' : ''}
              errorBorderColor="red.300"
              value={user.email}
              onChange={(e) => {
                checkField(e);
                setUser({ ...user, [e.target.name]: e.target.value });
              }}
              isDisabled={store.loading}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Phone</FormLabel>
            <Input
              type="text"
              name="phone"
              value={user.phone}
              onChange={(e) =>
                setUser({ ...user, [e.target.name]: e.target.value })
              }
              isDisabled={store.loading}
            />
          </FormControl>
        </Box>
        <Button
          ml="40px"
          colorScheme="green"
          onClick={sendHandler}
          isLoading={store.loading}
        >
          Save
        </Button>
      </Flex>
    </Flex>
  );
};

export default observer(UserSetup);
