import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import useRoutes from './routes/useRoutes';
import { Center, Flex, Spinner } from '@chakra-ui/react';
import Header from './components/Header';
import useMessage from './hooks/useMessage';
import { useStore } from './hooks/useStore';

function App() {
  const { store } = useStore();
  const routes = useRoutes(store.isAuth, store.isManager);

  useEffect(() => {
    if (localStorage.getItem('sttk')) {
      store.checkAuth();
    }
  }, []);

  useMessage(store.error);

  return (
    <>
      <Header />
      {store.loading && !store.isAuth ? (
        <Flex m="auto" minH="100vh">
          <Center flex="1">
            <Spinner thickness="4px" size="xl" color="green" />
          </Center>
        </Flex>
      ) : (
        routes
      )}
    </>
  );
}

export default observer(App);
