import React from 'react';
import { useEffect } from 'react';
import { useStore } from '../hooks/useStore';
import { observer } from 'mobx-react-lite';
import OrderCard from '../components/OrderCard';
import {
  Box,
  Flex,
  Divider,
  Heading,
  Image,
  Text,
  Button,
  VStack,
  Tag,
  Accordion,
} from '@chakra-ui/react';

const Orders = () => {
  const { store } = useStore();

  useEffect(() => {
    store.getOrders();
  }, []);
  return (
    <Accordion allowToggle>
      {store.orders.map((order) => (
        <OrderCard key={order.id} order={order} />
      ))}
    </Accordion>
  );
};

export default observer(Orders);
