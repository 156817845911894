import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Login from '../components/Login';
import MainPage from '../pages/MainPage';
import Register from '../components/Register';
import UserSetup from '../components/UserSetup';
import StockMainPage from '../pages/StockMainPage';
import OrdersPage from '../pages/OrdersPage';
import PickupPage from '../pages/PickupPage';
import GiveoutPage from '../pages/GiveoutPage';

const useRoutes = (isAuth, role) => {
  if (isAuth) {
    return (
      <Routes>
        <Route path="/" element={<MainPage />}>
          <Route index element={<StockMainPage />} />
          <Route path="takeitem" element={<StockMainPage />} />
          <Route path="pickup" element={<PickupPage />} />
          <Route path="giveout" element={<GiveoutPage />} />
          <Route path="orders" element={<OrdersPage />} />
          <Route path="setup" element={<UserSetup />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Route>
      </Routes>
    );
  }
  return (
    <Routes>
      <Route path="/enter" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="*" element={<Navigate to="/enter" />} />
    </Routes>
  );
};

export default useRoutes;
