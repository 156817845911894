import React from 'react';
import { Box, Flex, Image, Text, HStack, Link } from '@chakra-ui/react';
import { LinkIcon } from '@chakra-ui/icons';
import { orderItemTag } from './OrderItemCard';

const OrderLinkCard = ({ item, status }) => {
  return (
    <Flex gap="20px" mb="20px" alignItems="stretch">
      {!['send', 'delivered', 'issued', 'assembled'].includes(status) && (
        <Flex
          bg={orderItemTag[item.status][0]}
          fontSize="xs"
          borderRightRadius="md"
          borderLeftRadius={['md', 'md', '0']}
          px="10px"
          alignItems="center"
        >
          {orderItemTag[item.status][1]}
        </Flex>
      )}
      <Box>
        {!!item.img && (
          <Image objectFit="cover" boxSize="50px" src={item.img} />
        )}
      </Box>
      <Box flex="1">
        <Box>
          <Link href={item.link} target="_blank">
            <HStack>
              <LinkIcon />
              <Text fontSize="xs" color="blue" noOfLines={1} w="250px">
                {item.link}
              </Text>
            </HStack>
          </Link>
        </Box>
        <Box>{item.description}</Box>
      </Box>
      {!!item.price && (
        <Box textAlign="center">
          <Text fontSize="sm">Item price</Text>
          <Text fontWeight="bold" color="green">
            {item.price} $
          </Text>
        </Box>
      )}
      <Box textAlign="center">
        <Text fontSize="sm">Count</Text>
        <Text fontWeight="bold">{item.count}</Text>
      </Box>

      {!!item.summ && (
        <Box textAlign="center">
          <Text fontSize="sm">Full price</Text>
          <Text fontWeight="bold" color="green">
            {item.summ} $
          </Text>
        </Box>
      )}
    </Flex>
  );
};

export default OrderLinkCard;
