import React from 'react';
import {
  SettingsIcon,
  ExternalLinkIcon,
  EditIcon,
  ChatIcon,
} from '@chakra-ui/icons';
import {
  Avatar,
  AvatarBadge,
  Box,
  Button,
  Divider,
  HStack,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Tag,
  Text,
} from '@chakra-ui/react';
import { Link as rLink } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStore } from '../hooks/useStore';

const UserMenu = () => {
  const { store } = useStore();
  return (
    <Box>
      <Menu>
        <MenuButton zIndex="10" pos="relative">
          <HStack>
            <Avatar src={store.userData?.avatar} size="lg" border="4px">
              {!store.messages.length || (
                <AvatarBadge
                  boxSize="15px"
                  borderWidth="2px"
                  bottom="100%"
                  mb="-10px"
                  bg="tomato"
                />
              )}
            </Avatar>
            <Text color="gray.500" fontWeight="medium">
              {store.userData?.login || store.userData?.email}
            </Text>
          </HStack>
        </MenuButton>
        <MenuList mt="-26px" pt="27px" borderTop="none" borderTopRadius="0">
          <MenuItem
            icon={
              <ChatIcon
                color={store.messages.length ? 'tomato' : 'grey'}
                boxSize="20px"
              />
            }
          >
            Notifications
            {!store.messages.length || (
              <Tag
                borderRadius="full"
                ml="10px"
                bg="tomato"
                textAlign="center"
                color="white"
                size="sm"
              >
                10
              </Tag>
            )}
          </MenuItem>
          <Divider />
          <MenuItem
            as={rLink}
            to="/setup"
            icon={<SettingsIcon boxSize="20px" color="gray.600" />}
          >
            User setup
          </MenuItem>
          <MenuItem
            as={rLink}
            to="/shop"
            icon={<EditIcon boxSize="20px" color="gray.600" />}
          >
            Shop setup
          </MenuItem>
          <MenuDivider />
          <MenuItem
            icon={<ExternalLinkIcon boxSize="20px" color="gray.600" />}
            onClick={() => store.logout()}
          >
            Logout
          </MenuItem>
        </MenuList>
      </Menu>
    </Box>
  );
};

export default observer(UserMenu);
