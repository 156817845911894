import $api from '../http';

export default class UserService {
  static async getCurrent() {
    return $api.get('/user');
  }

  static async update(userData) {
    return $api.put('/user/update', userData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  static async checkField(param) {
    return $api.post('/user/check', param);
  }
}
