import { observer } from 'mobx-react-lite';
import React from 'react';
import { Outlet } from 'react-router-dom';
import { Box, Flex, Spacer } from '@chakra-ui/react';

const MainPage = () => {
  return (
    <>
      <Box w="full" px="8px" mt="40px">
        <Box minH="90vh">
          <Flex maxW="1100px" direction="column" px="20px" mx="auto">
            <Outlet />
            <Spacer />
            <Box h="10px"></Box>
          </Flex>
        </Box>
        <Box mt="20px" h="200px" borderTopRadius="md"></Box>
      </Box>
    </>
  );
};

export default observer(MainPage);
