import React from 'react';
import {
  Box,
  Flex,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
} from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import OrderCardBody from './OrderCardBody';

export const orderTag = {
  created: ['gray.200', 'Создана'],
  processing: ['orange.200', 'В обработке'],
  checked: ['yellow.200', 'Проверена'],
  waitbill: ['gray.200', 'Выставление счета'],
  invoice: ['green.200', 'Счет выставлен'],
  paid: ['teal.200', 'Оплачен'],
  stock: ['cyan.200', 'Прибыла на склад'],
  assembled: ['blue.200', 'Собран'],
  send: ['purple.200', 'Отправлен'],
  ontheway: ['pink.200', ''],
  confirm: ['green.200', 'Отправка подтверждена'],
  delivered: ['green.200', 'Доставлен'],
  issued: ['green.200', 'Выдан'],
  deleted: ['red.200', 'Удален'],
  modified: ['red.200', 'Изменен'],
  wrong: ['red.200', 'Несоответствие'],
  updated: ['red.200', 'Обновлен'],
};

const OrderCard = ({ order }) => {
  return (
    <>
      <Flex mb="10px" flexDir={['column', 'column', 'row']} bg="white">
        <Flex
          bg={orderTag[order.status][0]}
          textAlign="center"
          borderRight="1px"
          borderRightColor="gray.300"
          borderLeftRadius="md"
          borderRightRadius={['md', 'md', '0']}
          w={['auto', 'auto', '100px']}
          fontSize="xs"
          alignItems="center"
          justifyContent="center"
        >
          {orderTag[order.status][1]}
        </Flex>
        <AccordionItem flex="1">
          <h2>
            <AccordionButton as={Flex} gap={['10px', '10px', '20px']}>
              <Flex
                gap={['5px', '5px', '20px']}
                flexDirection={['column', 'column', 'row']}
              >
                <Box color="green" fontWeight="bold">
                  {order.code}
                </Box>
                <Box>{new Date(order.orderDate).toLocaleDateString()}</Box>
              </Flex>
              <Box flex="1" alignSelf="center"></Box>
              <Box>{order.comment}</Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel px={['5px', '10px', '20px']}>
            <OrderCardBody order={order} />
          </AccordionPanel>
        </AccordionItem>
      </Flex>
    </>

    // <Box mb="30px" pt="20px" pb="10px" px="20px" bg="white" borderRadius="md">
    //   <Flex>
    //     <Heading flex="1" size="sm" color="gray.500">
    //       <Text as="span" color="green">
    //         {order.code}
    //       </Text>
    //       <Text as="span" ml="10px" fontWeight="normal">
    //         {new Date(order.orderDate).toDateString()}
    //       </Text>
    //     </Heading>
    //     <Box>{order.status}</Box>
    //     {order.status === 'stock' && (
    //       <Box>
    //         <Button onClick={() => setForm(true)}>Repack</Button>
    //         <ConfirmForm opened={form} close={setForm} orderId={order.id} />
    //       </Box>
    //     )}
    //   </Flex>
    //   <Divider mt="10px" mb="20px" />
    //   <Box>
    //     {order.items.map((item) => (
    //       <Flex
    //         key={item.id}
    //         gap={['10px', '10px', '20px']}
    //         mb={['40px', '40px', '20px']}
    //         flexDirection={['column', 'column', 'row']}
    //       >
    //         <Box
    //           w="80px"
    //           textAlign={['left', 'left', 'right']}
    //           fontWeight="bold"
    //           color="green.500"
    //         >
    //           {item.code}
    //         </Box>
    //         <Flex flex="1" gap="20px">
    //           <Box w="50px" h="50px" bg="gray.100">
    //             {!!item.images.length && (
    //               <Image
    //                 boxSize="50px"
    //                 objectFit="cover"
    //                 src={item.images[0].link}
    //               />
    //             )}
    //           </Box>
    //           <Box flex="1">
    //             <Text>{item.name}</Text>
    //             <Text as="strong">
    //               Package - {item.price}$ / {item.count}.pcs
    //             </Text>
    //           </Box>
    //         </Flex>
    //         <Flex
    //           textAlign="center"
    //           borderX="1px"
    //           borderY={['1px', '1px', '0']}
    //           px="10px"
    //           py={['5px', '5px', 'auto']}
    //           borderRadius={['md', 'md', 'none']}
    //           bg={['gray.100', 'gray.100', 'none']}
    //           borderColor="gray.300"
    //           flexDirection={['row', 'row', 'column']}
    //           gap={['15px', '15px', '0']}
    //         >
    //           <Text>Count</Text>
    //           <Text as="strong">{item.orderCount}</Text>
    //         </Flex>
    //         <Flex
    //           flexDirection={['column', 'column', 'row']}
    //           alignItems={['stretch', 'stretch', 'center']}
    //         >
    //           <>
    //             <Box>
    //               <Tag w="full" colorScheme="teal">
    //                 {item.status}
    //               </Tag>
    //             </Box>
    //           </>
    //         </Flex>
    //       </Flex>
    //     ))}
    //   </Box>
    // </Box>
  );
};

export default observer(OrderCard);
