import React, { useState } from 'react';
import { Box, Flex, Button, Spacer, Divider, Text } from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import { useStore } from '../hooks/useStore';
import OrderItemCard from './OrderItemCard';
import OrderLinkCard from './OrderLinkCard';
import ConfirmForm from './ConfirmForm';

function OrderCardBody({ order }) {
  const { store } = useStore();
  console.log(store);
  const [form, setForm] = useState(false);

  return (
    <>
      {order.items.map((item) => (
        <OrderItemCard key={item.id} item={item} order={order} />
      ))}
      {order.linkItems.map((item) => (
        <OrderLinkCard key={item.id} item={item} status={order.status} />
      ))}

      {order.sendDate && (
        <>
          <Divider mb="20px" />
          <Flex justifyContent="space-between">
            <Box fontSize="sm">
              {order.sendDate && (
                <Box>
                  Дата отправки -{' '}
                  {new Date(order.sendDate).toLocaleDateString()}
                </Box>
              )}
              {order.arriveDate && (
                <Box>
                  Дата доставки в Россию -{' '}
                  {new Date(order.arriveDate).toLocaleDateString()}
                </Box>
              )}
              {order.issDate && (
                <Box>
                  Дата выдачи - {new Date(order.issDate).toLocaleDateString()}
                </Box>
              )}
            </Box>
            <Box fontSize="sm">
              {order.sendWeight && (
                <Box>Вес отправки - {order.sendWeight} кг</Box>
              )}
              {order.arriveWeight && (
                <Box>Вес прибытия - {order.arriveWeight} кг</Box>
              )}
              {order.shipPrice && (
                <Flex gap="12px">
                  <Text>Сумма доставки - </Text>
                  <Text
                    fontWeight="bold"
                    color={order.shipPayed ? 'green' : 'red'}
                  >
                    {order.shipPrice}${' '}
                  </Text>
                  <Text fontSize="xs" color={order.shipPayed ? 'green' : 'red'}>
                    {order.shipPayed ? 'Оплачено' : 'Не оплачено'}
                  </Text>
                </Flex>
              )}
            </Box>
          </Flex>
        </>
      )}

      {['invoice', 'confirm', 'assembled'].includes(order.status) && (
        <>
          <Divider my="20px" />
          <Flex gap="20px">
            <Spacer />

            {order.status === 'confirm' && (
              <Box>
                <Button
                  onClick={() => setForm(true)}
                  colorScheme="green"
                  size="sm"
                  isLoading={store.loading}
                >
                  Repack
                </Button>
                <ConfirmForm opened={form} close={setForm} orderId={order.id} />
              </Box>
            )}

            {order.status === 'assembled' && (
              <Box>
                <Button
                  onClick={() => store.sendOrder(order.id)}
                  colorScheme="green"
                  size="sm"
                >
                  Send
                </Button>
                <ConfirmForm
                  opened={form}
                  close={setForm}
                  orderId={order.id}
                  loading={store.loading}
                />
              </Box>
            )}
          </Flex>
        </>
      )}
    </>
  );
}

export default observer(OrderCardBody);
